import BasePlugin from '../../../BasePlugin'
import EntityQueryRepository from '@/plugins/Test/infrastructure/domain/repository/EntityQueryRepository'
import EntitiesHandler from '@/plugins/Test/application/query/EntitiesHandler'

export default class ShowEntities extends BasePlugin {
  async execute () {
    let handler = new EntitiesHandler(new EntityQueryRepository())
    let entities = await handler.execute([])
    console.log(entities)
  }
}
